@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  background: #0a1211;
}


.glass {
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.bg {
  background-image: url(./assets/bg-3.png);
  /*background-position: bottom;*/
  background-repeat: no-repeat;
  /*background-size: cover;*/
}


.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(right, #3d6081, #f4db78);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;
  transition: all 0.5s linear;
}

.mint-button:hover {
    background: -webkit-linear-gradient(left, #60c657, #31742b);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    margin-left: 10px;
    box-shadow: 0 20px 25px -5px #f0f1f12b, 0 8px 10px -6px rgb(244, 219, 120);
}

.rgb {
    background: -webkit-linear-gradient(left, #6366f114, rgb(130 233 164 / 18%));
    animation: gradient-animation 4s ease infinite;
}
/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.menu-drop {
  -webkit-animation: menu-drop 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: menu-drop 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@-webkit-keyframes menu-drop {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes menu-drop {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.link {
  position: relative;
}

.link:focus,
.link:active {
  color: #82e9a4;
  font-weight: bold;
}
.link::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #82e9a4;
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.link:hover::before {
  transform: translate(-50%, 0) scaleX(1);
}

.menu-drop {
  -webkit-animation: menu-drop 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: menu-drop 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.subtitle {
  width: 300px;
}

/*.box-shadow {*/
/*  background-color: #0000008a;*/
/*  border-radius: 20px;*/
/*  box-shadow: rgb(0 0 0 / 25%) 0px 0px 20px 20px, rgb(0 0 0 / 12%) -5px 4px 20px 8px, rgb(0 0 0 / 12%) 0px 0px 20px 20px, rgb(0 0 0 / 17%) 0px 0px 20px 20px, rgb(0 0 0 / 9%) 0px -1px 20px 20px;*/
/*}*/
.box-shadow {
  /*box-shadow: rgb(70 52 75) 0px 0px 20px 20px, rgb(0 0 0 / 12%) -5px 4px 20px 8px, rgb(0 0 0 / 12%) 0px 0px 20px 20px, rgb(0 0 0 / 17%) 0px 0px 20px 20px, rgb(0 0 0 / 9%) 0px -1px 20px 20px;*/
  /*background-color: #46344b;*/
  /*border-radius: 20px;*/
  /*padding: 10px;*/
  color: #f4db78;
}

@-webkit-keyframes menu-drop {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
